<script>
export default {}
</script>

<template>
  <svg
    id="svg2"
    xmlns:dc="http://purl.org/dc/elements/1.1/"
    xmlns:cc="http://creativecommons.org/ns#"
    xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
    xmlns:svg="http://www.w3.org/2000/svg"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 799 416.25333"
    height="100"
    width="799"
    xml:space="preserve"
    version="1.1"
  >
    <defs id="defs6" />
    <g id="g10" transform="matrix(1.3333333,0,0,-1.3333333,0,416.25333)">
      <g id="g12" transform="scale(0.1)">
        <path
          id="path14"
          style="
            fill: currentColor;
            fill-opacity: 1;
            fill-rule: nonzero;
            stroke: none;
          "
          d="m 3475.91,2770.77 h 220.97 v 33.03 h -183.8 v 127.04 h 157.48 v 32.51 h -157.48 v 125.45 h 176.6 v 33.05 h -213.77 z m -390.9,0 h 37.2 v 351.08 h -37.2 v -163.67 h -187.93 v 163.67 h -37.21 v -351.08 h 37.21 v 153.84 h 187.93 z m -656.27,318.03 h 107.95 v 33.05 h -253.03 v -33.05 h 107.91 v -318.03 h 37.17 v 318.03"
        />
        <path
          id="path16"
          style="
            fill: currentColor;
            fill-opacity: 1;
            fill-rule: nonzero;
            stroke: none;
          "
          d="m 2020.22,1350.42 h 269.51 c 274.73,0 358.65,40.54 411.6,105.35 h -486.16 c -93.07,-0.81 -158.11,-59.06 -194.95,-105.04 v 679.95 h 248.74 c 214.29,0 418.69,44.71 510.67,105.3 L 2032,2135.93 c -99.56,0 -199.64,-71.18 -199.64,-168.95 V 662.039 h 492.72 c 228.87,0 405.52,39.981 481.25,105.293 h -786.11 v 583.088"
        />
        <path
          id="path18"
          style="
            fill: currentColor;
            fill-opacity: 1;
            fill-rule: nonzero;
            stroke: none;
          "
          d="m 965.547,1032.73 c -50.984,96.77 -165.84,294.57 -226.531,296.08 297.004,39.98 313.514,329.38 313.514,354.58 0,148.38 -76.104,452.59 -511.866,452.59 H 0 V 662.16 h 187.871 v 1368.47 h 285.363 c 364.063,0 392.34,-265.78 392.34,-347.24 0,-147.86 -64.082,-232.04 -149.781,-279.02 C 648.801,1370.3 579.719,1354.23 488.418,1340.14 660.48,1298.55 873.992,822.082 906.211,773.219 968.723,678.5 1014.85,627.141 1160.23,627.141 c 19.08,0 82.04,1.269 144.27,16.257 -119.95,31.032 -223.1,169.704 -338.953,389.332"
        />
        <path
          id="path20"
          style="
            fill: currentColor;
            fill-opacity: 1;
            fill-rule: nonzero;
            stroke: none;
          "
          d="m 3601.39,1350.42 h 269.5 c 274.74,0 358.64,40.54 411.59,105.35 h -486.14 c -93.08,-0.81 -158.11,-59.06 -194.95,-105.04 v 679.95 h 248.75 c 214.26,0 418.68,44.71 510.66,105.3 l -747.65,-0.05 c -99.55,0 -199.62,-71.18 -199.62,-168.95 V 662.039 h 492.72 c 228.86,0 405.51,39.981 481.25,105.293 h -786.11 v 583.088"
        />
        <path
          id="path22"
          style="
            fill: currentColor;
            fill-opacity: 1;
            fill-rule: nonzero;
            stroke: none;
          "
          d="m 5428.01,1455.77 c -93.08,-0.81 -158.11,-59.06 -194.94,-105.04 v 679.95 h 248.73 c 214.26,0 418.68,44.71 510.66,105.3 l -747.65,-0.05 c -99.55,0 -199.62,-71.18 -199.62,-168.95 V 662.07 h 187.88 v 688.35 h 269.48 c 274.74,0 358.64,40.54 411.6,105.35 h -70.82 -415.32"
        />
        <path
          id="path24"
          style="
            fill: currentColor;
            fill-opacity: 1;
            fill-rule: nonzero;
            stroke: none;
          "
          d="M 1169.02,6.21094 V 163.102 c -0.04,2.168 -1.76,3.918 -3.98,4 l -20.37,0.019 c -2.21,0 -4.03,-1.82 -4.05,-4.039 V 125.711 6.19141 c 0.02,-2.17969 1.74,-3.92188 3.97,-3.99219 l 20.37,-0.02734 c 2.21,0 4.03,1.79687 4.06,4.03906"
        />
        <path
          id="path26"
          style="
            fill: currentColor;
            fill-opacity: 1;
            fill-rule: nonzero;
            stroke: none;
          "
          d="m 467.043,2.19922 c 2.156,0.03125 3.898,1.74219 3.988,3.94922 l 0.008,17.48046 c 0,2.2227 -1.797,4.043 -4.012,4.0508 l -67.511,0.0117 -0.008,135.3986 c -0.024,2.18 -1.758,3.93 -3.961,4.012 l -20.328,0.019 c -2.223,0 -4.035,-1.82 -4.063,-4.039 L 371.168,6.10156 c 0.062,-2.16015 1.82,-3.87109 4.008,-3.90234 h 91.855 0.012"
        />
        <path
          id="path28"
          style="
            fill: currentColor;
            fill-opacity: 1;
            fill-rule: nonzero;
            stroke: none;
          "
          d="m 1312.76,97.5898 -11.19,5.0192 c -18.07,8.051 -24.9,12.2 -24.9,23.961 0,10.719 7.94,17.391 20.74,17.391 11.07,0 19.01,-3.981 25.01,-12.582 l 0.27,-0.309 c 1.16,-1.738 3.6,-2.34 5.52,-1.16 13.55,8.828 14.91,9.719 15.03,9.801 0.91,0.59 1.51,1.5 1.74,2.559 0.23,1.05 0.02,2.128 -0.61,3.082 l -0.42,0.589 c -10.62,15.879 -25.57,23.289 -47.03,23.289 -28.2,0 -47.9,-17.949 -47.9,-43.628 0,-25.6137 16.45,-38.102 41.13,-48.9809 l 11.19,-4.9922 c 15.18,-6.8398 25.14,-12.3594 25.14,-26.1172 0,-12.0117 -10.67,-19.7617 -27.17,-19.7617 -14.51,0 -26.1,6.1602 -33.51,17.832 l -0.71,1.0782 c -0.98,1.8593 -3.31,2.6992 -5.35,1.7382 -16.38,-8.1562 -16.78,-8.3593 -16.78,-8.3593 -1.99,-0.9883 -2.79,-3.418 -1.77,-5.4493 l 0.4,-0.7304 C 1253.72,11.3203 1274.3,0 1299.55,0 c 33.94,0 55.04,17.7109 55.04,46.2305 0,27.3398 -15.33,39.5312 -41.83,51.3593"
        />
        <path
          id="path30"
          style="
            fill: currentColor;
            fill-opacity: 1;
            fill-rule: nonzero;
            stroke: none;
          "
          d="M 880.484,5.17188 V 163.07 c -0.023,2.168 -1.754,3.922 -3.98,3.989 l -18.645,0.023 c -2.222,0 -4.035,-1.812 -4.054,-4.031 L 853.789,62.6719 754.652,166.59 c -0.73,0.84 -1.777,1.351 -2.871,1.351 -2.113,0 -3.816,-1.722 -3.816,-3.839 L 747.969,6.19141 c 0.023,-2.17188 1.742,-3.92188 3.969,-3.99219 L 770.59,2.17188 c 2.219,0 4.027,1.80859 4.051,4.03906 L 774.645,106.539 873.891,2.53125 c 0.726,-0.78906 1.718,-1.21094 2.769,-1.21094 2.113,0 3.824,1.71875 3.824,3.85157"
        />
        <path
          id="path32"
          style="
            fill: currentColor;
            fill-opacity: 1;
            fill-rule: nonzero;
            stroke: none;
          "
          d="m 303.59,145.672 -0.016,17.488 c -0.078,2.16 -1.828,3.879 -4.008,3.899 H 182.93 c -2.164,-0.02 -3.914,-1.739 -3.989,-3.957 l -0.011,-17.43 c 0,-2.223 1.793,-4.031 4.015,-4.063 h 44.106 L 227.055,6.19141 c 0.035,-2.17188 1.75,-3.92188 3.965,-3.99219 l 20.371,-0.02734 c 2.218,0 4.035,1.80859 4.058,4.02734 V 141.609 h 44.129 c 2.215,0.032 4.012,1.84 4.012,4.063"
        />
        <path
          id="path34"
          style="
            fill: currentColor;
            fill-opacity: 1;
            fill-rule: nonzero;
            stroke: none;
          "
          d="M 1069.25,167.07 H 952.621 c -2.172,-0.031 -3.926,-1.75 -4,-3.968 l -0.008,-17.43 c 0,-2.223 1.793,-4.031 4.024,-4.063 h 44.101 V 6.19141 c 0.024,-2.17969 1.746,-3.92188 3.962,-3.99219 l 20.38,-0.02734 c 2.22,0 4.03,1.80859 4.05,4.03906 l 0.01,135.39806 h 44.13 c 2.2,0.032 4,1.84 4,4.063 l -0.01,17.488 c -0.07,2.16 -1.83,3.879 -4.01,3.91"
        />
        <path
          id="path36"
          style="
            fill: currentColor;
            fill-opacity: 1;
            fill-rule: nonzero;
            stroke: none;
          "
          d="M 585.066,57.6797 606.734,109.23 627.973,57.6797 Z M 679.094,6.03906 c 0,0.48047 -0.129,0.85938 -0.274,1.30078 L 610.605,165.699 c -1.429,2.742 -5.746,2.84 -7.269,-0.09 L 535.297,7.21875 c -0.109,-0.33984 -0.207,-0.73047 -0.207,-1.17969 0,-2.14843 1.742,-3.89843 3.894,-3.89843 l 18.204,0.00781 c 0.585,0 2.339,0.14062 2.835,0.32031 1.383,0.44141 2.536,1.47266 3.047,2.61328 l 11.496,27.32817 h 63.879 L 649.859,5.28125 c 0.629,-1.33984 1.786,-2.35937 3.129,-2.79297 0.543,-0.19922 2.293,-0.33984 2.91,-0.33984 l 19.29,-0.00781 c 2.152,0 3.906,1.75 3.906,3.89843"
        />
        <path
          id="path38"
          style="
            fill: currentColor;
            fill-opacity: 1;
            fill-rule: nonzero;
            stroke: none;
          "
          d="M 49.9766,57.6797 71.6445,109.23 92.8828,57.6797 Z M 117.902,2.48828 c 0.532,-0.19922 2.289,-0.33984 2.903,-0.33984 l 19.293,-0.00781 c 2.152,0 3.894,1.75 3.894,3.89843 0,0.48047 -0.125,0.85938 -0.258,1.30078 L 75.5117,165.691 c -1.414,2.75 -5.7422,2.848 -7.2695,-0.082 L 0.207031,7.17969 C 0.109375,6.92188 0,6.51953 0,6.03906 0,3.89063 1.74219,2.14063 3.88672,2.14063 L 22.1016,2.14844 c 0.5742,0 2.3359,0.14062 2.8203,0.32031 1.3789,0.44141 2.539,1.46094 3.0625,2.61328 l 11.5,27.32817 H 103.359 L 114.766,5.28125 c 0.636,-1.33984 1.793,-2.37109 3.136,-2.79297"
        />
        <path
          id="path40"
          style="
            fill: currentColor;
            fill-opacity: 1;
            fill-rule: nonzero;
            stroke: none;
          "
          d="m 1808.85,55.2617 24.06,57.2073 23.57,-57.2073 z M 1832.44,168.969 1760.77,2.08984 h 26 l 12.72,30.55076 h 66.35 L 1878.6,2.08984 h 27.18 L 1833.89,168.969 h -1.45"
        />
        <path
          id="path42"
          style="
            fill: currentColor;
            fill-opacity: 1;
            fill-rule: nonzero;
            stroke: none;
          "
          d="m 2802.24,95.6914 -11.34,5.0506 c -17.78,7.899 -25.95,12.477 -25.95,25.469 0,11.301 8.41,19 22.36,19 12.99,0 21.92,-5.281 28.12,-15.879 l 19.47,12.77 c -10.34,17.277 -25.47,25.687 -48.08,25.687 -27.87,0 -46.87,-17.519 -46.87,-42.559 0,-25.5112 16.59,-37.4683 40.61,-48.0816 l 11.34,-5.0078 c 14.41,-6.5312 26.2,-12.3008 26.2,-27.6914 0,-13.2304 -11.55,-21.4297 -28.87,-21.4297 -16.82,0 -29.57,7.9493 -37.01,22.1407 L 2730.57,34.3398 C 2741.64,13.4102 2761.6,0 2789.47,0 c 31.04,0 54.09,15.1094 54.09,45.1602 0,27.6914 -16.57,39.4804 -41.32,50.5312"
        />
        <path
          id="path44"
          style="
            fill: currentColor;
            fill-opacity: 1;
            fill-rule: nonzero;
            stroke: none;
          "
          d="m 2954.83,73.5586 h 69.5 v 22.3906 h -69.5 v 46.8828 h 78.38 v 22.789 H 2929.12 V 2.08984 h 107.5 V 24.9805 h -81.79 v 48.5781"
        />
        <path
          id="path46"
          style="
            fill: currentColor;
            fill-opacity: 1;
            fill-rule: nonzero;
            stroke: none;
          "
          d="m 2622.42,2.08984 h 25.75 V 165.602 h -25.75 V 2.08984"
        />
        <path
          id="path48"
          style="
            fill: currentColor;
            fill-opacity: 1;
            fill-rule: nonzero;
            stroke: none;
          "
          d="m 2226.47,55.2617 24.08,57.2073 23.55,-57.2073 z M 2250.08,168.969 2178.43,2.08984 h 25.95 l 12.75,30.55076 h 66.33 l 12.78,-30.55076 h 27.14 L 2251.51,168.969 h -1.43"
        />
        <path
          id="path50"
          style="
            fill: currentColor;
            fill-opacity: 1;
            fill-rule: nonzero;
            stroke: none;
          "
          d="m 2447.28,24.9609 h -19 V 142.832 h 19 c 42.33,0 60.85,-24.332 60.85,-58.9531 0,-34.6172 -18.52,-58.918 -60.85,-58.918 z m 1.45,140.6601 h -46.18 V 2.10938 h 46.18 c 53.64,0 86.05,33.42972 86.05,81.76952 0,48.3201 -32.67,81.7421 -86.05,81.7421"
        />
        <path
          id="path52"
          style="
            fill: currentColor;
            fill-opacity: 1;
            fill-rule: nonzero;
            stroke: none;
          "
          d="m 2036.37,84.6406 h -25.73 v 58.1914 h 25.73 c 24.05,0 34.4,-9.434 34.4,-29.141 0,-19.441 -10.11,-29.0504 -34.4,-29.0504 z m 61.09,29.0504 c 0,31.028 -21.88,51.93 -60.09,51.93 h -52.44 V 2.10938 h 25.71 V 62.5195 h 26.73 3.1 l 36.54,-60.41012 h 28.14 L 2065.69,67.082 c 20.45,7.4493 31.77,23.7969 31.77,46.609"
        />
        <path
          id="path54"
          style="
            fill: currentColor;
            fill-opacity: 1;
            fill-rule: nonzero;
            stroke: none;
          "
          d="m 1644.13,82.7188 h -25.28 v 60.1132 h 25.28 c 24.04,0 34.36,-9.871 34.36,-30.102 0,-19.9214 -10.08,-30.0112 -34.36,-30.0112 z m 0.96,82.9022 h -51.96 V 2.10938 h 25.72 V 59.8203 h 26.24 c 37.98,0 60.09,20.4688 60.09,52.9097 0,31.75 -21.9,52.891 -60.09,52.891"
        />
        <path
          id="path56"
          style="
            fill: currentColor;
            fill-opacity: 1;
            fill-rule: nonzero;
            stroke: none;
          "
          d="m 3257.39,2.33203 h 25.7 V 165.871 h -25.7 V 2.33203"
        />
        <path
          id="path58"
          style="
            fill: currentColor;
            fill-opacity: 1;
            fill-rule: nonzero;
            stroke: none;
          "
          d="M 4067.14,58.4492 3962.28,168.301 h -1.86 V 2.35156 h 24 V 109.859 L 4089.24,0 h 1.94 v 165.871 h -24.04 V 58.4492"
        />
        <path
          id="path60"
          style="
            fill: currentColor;
            fill-opacity: 1;
            fill-rule: nonzero;
            stroke: none;
          "
          d="m 3437.17,95.9492 -11.34,5.0308 c -17.77,7.918 -25.96,12.481 -25.96,25.489 0,11.32 8.43,19.011 22.38,19.011 12.98,0 21.87,-5.308 28.11,-15.898 l 19.51,12.758 c -10.34,17.289 -25.52,25.691 -48.09,25.691 -27.91,0 -46.92,-17.519 -46.92,-42.551 0,-25.4917 16.6,-37.48 40.68,-48.0581 l 11.24,-5.0625 c 14.47,-6.5 26.25,-12.2774 26.25,-27.6485 0,-13.25 -11.56,-21.4218 -28.85,-21.4218 -16.84,0 -29.58,7.9297 -37.04,22.1211 L 3365.5,34.6094 c 11.05,-20.9375 31.01,-34.371119 58.92,-34.371119 31.02,0 54.08,15.121119 54.08,45.171919 0,27.6796 -16.58,39.4687 -41.33,50.539"
        />
        <path
          id="path62"
          style="
            fill: currentColor;
            fill-opacity: 1;
            fill-rule: nonzero;
            stroke: none;
          "
          d="m 4231.52,25.2188 h -19 V 143.07 h 19 c 42.32,0 60.82,-24.3 60.82,-58.9411 0,-34.5976 -18.5,-58.9101 -60.82,-58.9101 z m 1.45,140.6522 h -46.16 V 2.35156 h 46.16 c 53.6,0 86.06,33.43754 86.06,81.77734 0,48.3401 -32.68,81.7421 -86.06,81.7421"
        />
        <path
          id="path64"
          style="
            fill: currentColor;
            fill-opacity: 1;
            fill-rule: nonzero;
            stroke: none;
          "
          d="m 3589.8,165.871 h -25.75 V 2.35156 h 97.85 V 25.2188 h -72.1 V 165.871"
        />
        <path
          id="path66"
          style="
            fill: currentColor;
            fill-opacity: 1;
            fill-rule: nonzero;
            stroke: none;
          "
          d="m 3784.33,55.5313 24.03,57.1987 23.55,-57.1987 z M 3807.87,169.25 3736.2,2.35156 h 26 l 12.72,30.53904 h 66.37 l 12.74,-30.53904 h 27.2 L 3809.3,169.25 h -1.43"
        />
        <path
          id="path68"
          style="
            fill: currentColor;
            fill-opacity: 1;
            fill-rule: nonzero;
            stroke: none;
          "
          d="m 5040.92,93.2813 h -78.36 v 72.3397 h -25.73 V 2.08984 h 25.73 V 70.4492 h 78.36 V 2.08984 h 25.71 V 165.621 h -25.71 V 93.2813"
        />
        <path
          id="path70"
          style="
            fill: currentColor;
            fill-opacity: 1;
            fill-rule: nonzero;
            stroke: none;
          "
          d="m 5451,76.9492 -79.81,92.0198 h -1.2 V 2.08984 h 24.29 V 108.172 l 55.5,-66.59 h 1.22 l 55.79,66.59 V 2.08984 h 24.29 V 168.969 h -1.22 L 5451,76.9492"
        />
        <path
          id="path72"
          style="
            fill: currentColor;
            fill-opacity: 1;
            fill-rule: nonzero;
            stroke: none;
          "
          d="m 5894.03,95.6914 -11.3,5.0506 c -17.8,7.899 -25.93,12.477 -25.93,25.469 0,11.301 8.4,19 22.32,19 13.01,0 21.89,-5.281 28.15,-15.879 l 19.47,12.77 c -10.34,17.277 -25.49,25.687 -48.07,25.687 -27.91,0 -46.91,-17.519 -46.91,-42.559 0,-25.5112 16.58,-37.4683 40.65,-48.0816 l 11.3,-5.0078 c 14.4,-6.5312 26.22,-12.3008 26.22,-27.6914 0,-13.2304 -11.56,-21.4297 -28.88,-21.4297 -16.81,0 -29.58,7.9493 -37.02,22.1407 L 5822.36,34.3398 C 5833.45,13.4102 5853.41,0 5881.29,0 c 31.04,0 54.11,15.1094 54.11,45.1602 0,27.6914 -16.62,39.4804 -41.37,50.5312"
        />
        <path
          id="path74"
          style="
            fill: currentColor;
            fill-opacity: 1;
            fill-rule: nonzero;
            stroke: none;
          "
          d="m 4591.08,24.2305 h -35.34 v 52.7187 h 33.92 c 19.23,0 29.33,-10.3398 29.33,-26.4804 0,-15.3985 -9.15,-26.2383 -27.91,-26.2383 z M 4555.74,143.52 h 30.79 c 17.81,0 25.27,-9.149 25.27,-22.86 0,-13.461 -7.73,-23.0389 -24.76,-23.0389 h -31.3 z m 56.75,-54.8012 c 15.87,4.8203 24.53,17.9922 24.53,34.3712 0,23.808 -16.09,42.531 -48.58,42.531 h -58.16 V 2.10938 h 62.25 c 34.15,0 51.95,19.98042 51.95,46.44142 0,22.8476 -12.29,35.5781 -31.99,40.168"
        />
        <path
          id="path76"
          style="
            fill: currentColor;
            fill-opacity: 1;
            fill-rule: nonzero;
            stroke: none;
          "
          d="m 4760.75,55.2617 24.05,57.2073 23.54,-57.2073 z M 4784.3,168.969 4712.68,2.08984 h 25.95 l 12.78,30.55076 h 66.33 L 4830.5,2.08984 h 27.15 l -71.9,166.87916 h -1.45"
        />
        <path
          id="path78"
          style="
            fill: currentColor;
            fill-opacity: 1;
            fill-rule: nonzero;
            stroke: none;
          "
          d="m 5193.9,55.2617 24.07,57.2073 23.55,-57.2073 z M 5217.48,168.969 5145.8,2.08984 h 25.99 l 12.78,30.55076 h 66.33 l 12.76,-30.55076 h 27.16 l -71.9,166.87916 h -1.44"
        />
        <path
          id="path80"
          style="
            fill: currentColor;
            fill-opacity: 1;
            fill-rule: nonzero;
            stroke: none;
          "
          d="m 5658.47,55.2617 24.01,57.2073 23.59,-57.2073 z M 5682.03,168.969 5610.37,2.08984 h 25.95 l 12.76,30.55076 h 66.37 l 12.74,-30.55076 h 27.14 L 5683.46,168.969 h -1.43"
        />
      </g>
    </g>
  </svg>
</template>
